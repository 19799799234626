.margin-bottom-30 {
  margin-bottom: 30px;
}
.sm-margin-bottom-20 {
  margin-bottom: 20px;
}
.header .logo img {
  height: 120px;
  padding: 0;
  margin: 0;
}
.footer {
  margin-top: 30px;
}
.footer #logo-footer {
  height: 80px;
  margin-bottom: 10px;
}
.content-sm {
  padding-top: 0;
}
#glitter-edit-menu .glitter-version-previous {
  width: 13.2em;
}
#glitter-edit-menu .glitter-tab-versions {
  width: 7em;
}
#glitter-edit-menu .glitter-version-next {
  width: 10.777778em;
}
#glitter-edit-menu .glitter-version-edit-toggle {
  width: 6.9em;
}
#glitter-edit-menu .glitter-version-edit-toggle span:before {
  width: 2.85185185em;
}
#glitter-edit-menu .glitter-version-edit-toggle span:after {
  width: 2.85185185em;
}
#glitter-edit-menu .glitter-add-new-page:before {
  width: 2.1em;
}
.forgot-password {
  cursor: pointer;
}
/*# sourceMappingURL=styles.css.map */